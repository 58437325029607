import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit {
  @ViewChild('popup', { static: false }) popup: ElementRef;
  @ViewChild('closeBtn', { static: false }) closeBtn: ElementRef;
  @ViewChild('largeImage', { static: false }) largeImage: ElementRef;
  @ViewChild('imageIndex', { static: false }) imageIndex: ElementRef;
  @ViewChild('leftArrow', { static: false }) leftArrow: ElementRef;
  @ViewChild('rightArrow', { static: false }) rightArrow: ElementRef;
  
  constructor(private _http: HttpClient, 
      private elRef: ElementRef, 
      private renderer: Renderer2) { }

  images: NodeListOf<Element>;
  AllImages: any = [];
  showPopup:boolean = false;
  CurrentImg:string = "";
  CurrentIdx:number=1;
  index = 0; // Will Track Our Current Image

  ngOnInit(): void {
    // this.images = document.querySelectorAll('.image');

    // this.images.forEach((item: Element, i: number) => {
    //   item.addEventListener('click', () => {
    //     this.updateImage(i);
    //     (this.popup.nativeElement as HTMLElement).classList.toggle('active');
    //   });
    // });
   // (this.popup.nativeElement as HTMLElement).classList.toggle('active');
    this.GetPhoto(1055);

  }

  updateImage(i:number): void {

    const popupElement = this.elRef.nativeElement.querySelector('.popup');
    this.renderer.addClass(popupElement, 'active');

    //path = "https://smc.cusmc.org/gallery/"+path;

    let data = this.AllImages[i];
    console.log(data);
    this.CurrentImg =  "https://smc.cusmc.org/gallery/"+data.Name;
    this.CurrentIdx = i;
    
    //let path = `img/${i + 1}.jpg`;
    //let path = `assets/Img/test/${i+1}.jpg`;
    
   // (this.largeImage.nativeElement as HTMLImageElement).src = path;
   // (this.imageIndex.nativeElement as HTMLElement).innerHTML = `0${i + 1}`;
    this.index = i;
    
  }

  GetPhoto(id:any) {
    //this.loading = true;
      var url = "https://smc.cusmc.org/api/GalleryAPI/getFiles?dir=1055";
      this._http.get(url)
      .subscribe(data1 => {
        console.log(data1);
        this.AllImages = data1;
        console.log(this.AllImages);
        //this.loading = false;
      },
      err => {
        console.log(err);
      }
    )
  }

  onCloseClick(): void {
    // (this.popup.nativeElement as HTMLElement).classList.toggle('active');
    const popupElement = this.elRef.nativeElement.querySelector('.popup');
    this.renderer.removeClass(popupElement, 'active');

  }

  onLeftArrowClick(): void {
    //alert(this.index);
    if (this.index > 0) {
      this.index --;
      this.updateImage(this.index);
    }
  }

  onRightArrowClick(): void {
    //alert(this.index);
    if (this.index < this.AllImages.length - 1) {
      this.updateImage(this.index + 1);
    }
  }

}
