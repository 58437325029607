import { Component, Input, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-faculty',
  templateUrl: './faculty.component.html',
  styleUrls: ['./faculty.component.css']
})

export class FacultyComponent implements OnInit {
  
  @Input() deptId: string = ""; 
  @Input() deptNm: string = ""; 

  PageData: any= [];
  Dept_id:string = "";
  Dept_nm:string = "";
  loading:number = 1;
  constructor(private route : ActivatedRoute,
    private _http: HttpClient
    ) { }


    
  ngOnInit() {
    this.loading = 1;
    
    this.route.paramMap 
      .subscribe(params => {
        this.Dept_id = params.get('Dept_id');
        this.Dept_nm = params.get('Dept_nm');
      })
      if (this.Dept_id) {
        this.fetchData();
      }
  }

  ngOnChanges() {
    if (this.deptId) {
      this.Dept_id = this.deptId;
      this.Dept_nm = this.deptNm;
      this.fetchData();
    }
  }

  fetchData() {
    this.loading = 1;
    var url = `https://smc.cusmc.org/api/HR/EmpmastsAPI/EmpSitebyDept_id?Dept_id=${this.Dept_id}`;
    this._http.get(url).subscribe(
      (data: any) => {
        this.PageData = data;
        this.PageData.forEach(d => (d.ImgProfile = "data:image/jpeg;base64," + d.ImgProfile));
        this.loading++;
      },
      err => {
        console.log(err);
      }
    );
  }
}