import { HttpClient } from '@angular/common/http';
import { Component, OnInit,Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-photoview',
  templateUrl: './photoview.component.html',
  styleUrls: ['./photoview.component.css']
})
export class PhotoviewComponent implements OnInit {
  id: string;
  AllImages: any = [];
  loading:boolean = false;

  constructor(private route : ActivatedRoute,
    private _http: HttpClient,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public receiveddata: any,
  ) { }

    ngOnInit() {
      this.loading = false;
      this.GetPhoto(this.receiveddata.id);
      this.loading = true;
    }

    GetPhoto(id:any) {
      this.loading = true;
        var url = "https://smc.cusmc.org/api/GalleryAPI/getFiles?dir="+ id;
        this._http.get(url)
        .subscribe(data1 => {
          console.log(data1);
          this.AllImages = data1;
          console.log(this.AllImages);
          this.loading = false;
        },
        err => {
          console.log(err);
        }
      )
    }
  }


