import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { FacultyComponent } from './faculty/faculty.component';
import { GencircularComponent } from './gencircular/gencircular.component';

import { HomeComponent } from './home/home.component';
import { PhotoComponent } from './photo/photo.component';
import { PhotoviewComponent } from './photoview/photoview.component';
import { SubpageComponent } from './subpage/subpage.component';
import { VideoComponent } from './video/video.component';
import { TestComponent } from './test/test.component';



const routes: Routes = [

  { 
    path: '', 
    redirectTo: 'home', 
    pathMatch: 'full' 
  },
  { 
    path: 'home', 
    component:HomeComponent, 
    data: { title: 'Dashboard', breadcrumb: 'DASHBOARD'}
  },
  {
    path: 'subpage/:PageName',
    component:SubpageComponent,
    data: {title: 'Subpage', breadcrumb: 'SUBPAGE'}
  },    
  {
    path: 'faculty/:Dept_id/:Dept_nm',
    component:FacultyComponent,
    data: {title: 'Faculty', breadcrumb: "FACULTY"}
  },
  {
    path : 'contact-us',
    component:ContactUsComponent,
    data: {title: 'Contact-us', breadcrumb: "CONTACT-US"}
  },
  {
    path : 'photo/:Site_nm',
    component:PhotoComponent,
    data: {title: 'photo', breadcrumb: "PHOTO"}
  },
  {
    path : 'photo',
    component:PhotoComponent,
    data: {title: 'photo', breadcrumb: "PHOTO"}
  },
  {
    path : 'video',
    component:VideoComponent,
    data: {title: 'video', breadcrumb: "VIDEO"}
  },
  {
    path : 'photoview/:id',
    component:PhotoviewComponent,
    data: {title: 'photoview', breadcrumb: "PHOTOVIEW"}
  },
  {
    path : 'Circular/:Category',
    component:GencircularComponent,
    data: {title: 'studcircular', breadcrumb: "STUDCIRCULAR"}
  },
  {
    path : 'test',
    component:TestComponent,
    data: {title: 'test', breadcrumb: "test"}
  }
  // {
    
  //   path: '', 
  //   children: [
  //     { 
  //       path: 'user', 
  //       loadChildren: 'src/app/user/user.module#UserModule',
  //       data: { title: 'user'} 
  //     }
  //   ]
  // }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
