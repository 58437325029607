import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-subpage',
  templateUrl: './subpage.component.html',
  styleUrls: ['./subpage.component.css']
})
export class SubpageComponent implements OnInit {
  PageData:any = {};
  PageName:string = "";
  PageCont:string = "";
  PageDesc:string = "";
  loading:Boolean = false;
  constructor(private route : ActivatedRoute,
    private _http: HttpClient,
     private sanitizer: DomSanitizer
    ) { 
    
  }

  ngOnInit() {
    this.loading = true;
    this.route.paramMap
      .subscribe(params => {
          this.PageName = params.get('PageName');
      })
      var url = "https://smc.cusmc.org/api/Website/WebpagesAPI/GetDatabyPage_nm?Page_nm="+this.PageName;
      this._http.get(url)
        .subscribe(data1 => {
          console.log(data1);
          this.PageData = data1;
          this.PageData.Page_cont = this.sanitizer.bypassSecurityTrustHtml(this.PageData.Page_cont);
          this.loading = false;
        },
          err => {
            console.log(err);
       });      
  }

}
